<template>
  <v-app>
    <v-main >
      <v-container
        class="fill-height pa-0"
        fluid
      >
        <!-- <v-img src="@/assets/web3.jpg" lazy-src="@/assets/web3-blur.jpg"> -->
        <v-img src="@/assets/pemkot.jpeg" lazy-src="@/assets/pemkot.jpeg">
        <v-row
          justify="center"
        >
          <v-col
            class="xm"
            cols="12"
            sm="8"
            md="4"
          >
            <base-material-card
              icon="mdi-login"
              color="primary"
              style="background:rgba(255,255,255,0.9)"
            >
            <v-img src="@/assets/logo9.png" height="140" style="margin-top:-70px" contain></v-img>
            <div class="d-flex">
              <span v-if="hasError" class="red--text mx-auto" v-html="err.message"></span>
            </div>

            <v-form @submit.prevent="handleSubmit" method="post">
              <v-card-text>
                  <div v-if="errors.length">
                    <center>
                      <!-- <b style="color:red">Terjadi kesalahan:</b> -->
                      <!-- <h5 v-for="error in errors" :key="error" style="color:red">{{ error }}</h5> -->
                      <span v-for="error in errors" :key="error" class="red--text mx-auto">{{ error }}</span>
                    </center>
                  </div>
                  <v-text-field
                    :label="$t('nip')"
                    name="login"
                    prepend-icon="mdi-card-account-details"
                    :error="hasError"
                    v-model="username"
                    type="text"
                    counter="18"
                    style="margin-top:-10px"
                  />
                  <v-text-field
                    id="password"
                    :label="$t('password')"
                    name="password"
                    prepend-icon="mdi-lock"
                    :error="hasError"
                    v-model="password"
                    :type="seePassword ? 'text' : 'password'"
                    @click:append="()=>(seePassword =! seePassword)"
                    :append-icon="seePassword ? 'mdi-eye' : 'mdi-eye-off' "
                    style="margin-top:-5px"
                  />
                  <v-select
                    :items="tahun"
                    :label="$t('year')"
                    v-model="year"
                    prepend-icon="mdi-calendar-clock"
                    style="margin-top:0px"
                  />
              </v-card-text>
              <v-card-actions style="margin-top:-30px">
                <v-checkbox class="pl-2" v-model="simpanNIP" label="Simpan NIP"></v-checkbox>
                <v-spacer />
                <v-btn :disabled="hasError || !username || !password || !year" type="submit" color="primary" :loading="loading">
                  {{ $t('login-btn') }}
                </v-btn>
              </v-card-actions>
              <div class="text-center d-flex">
                <hr class="my-auto flex-grow-1" style="border-top: 1px solid rgba(0, 0, 0, 0.2) !important;">
                &nbsp;atau&nbsp;
                <hr class="my-auto flex-grow-1" style="border-top: 1px solid rgba(0, 0, 0, 0.2) !important;">
              </div>
              <div justify="center" align="center" class="pt-5">
                <v-btn href="https://gercep-asik.bandung.go.id/" color="default" outlined>
                  <v-img src="@/assets/gercep-logo.png" height="30" width="30" contain></v-img>Sign In with Gercep-Asik
                </v-btn>
              </div>
            </v-form>

            </base-material-card>
          </v-col>
        </v-row>
        <div :class="{'d-flex flex-column flex-md-column justify-end': $vuetify.breakpoint.lgAndDown, 'd-none': $vuetify.breakpoint.lgAndUp}">
          <div class="d-flex align-end">
            <v-row
              justify="center"
            >
              <v-col>
                <v-carousel
                  cycle
                  height="60"
                  hide-delimiter-background
                  show-arrows-on-hover
                  :show-arrows="false"
                  hide-delimiters
                >
                  <template v-for="(slide,i) in quotes">
                    <v-carousel-item
                      :key="i"
                      @click="newTabLink(slide.to)"
                      v-if='slide.type=="quote"'
                    >
                      <v-sheet
                        color="rgba(60, 60, 60, 0.7)"
                        height="auto"
                      >
                        <v-row
                          align="center"
                          justify="center"
                        >
                          <div class="display-1 pa-6" style="text-align:center;color:white">{{ slide.content }}</div>
                        </v-row>
                      </v-sheet>
                    </v-carousel-item>
                    <v-carousel-item
                      v-else
                      @click="newTabLink(slide.to)"
                      :key="i"
                      :src="slide.content"
                      contain
                    ></v-carousel-item>
                  </template>
                </v-carousel>
              </v-col>
            </v-row>
          </div>
        </div>
        <div :class="{'d-flex flex-column': $vuetify.breakpoint.smAndDown, 'd-flex justify-space-between justify-end': $vuetify.breakpoint.lgAndDown, 'd-none': $vuetify.breakpoint.lgAndUp}">
          <div :class="{'d-flex align-center justify-center': $vuetify.breakpoint.smAndDown, 'd-flex align-center': $vuetify.breakpoint.mdAndUp}">
            <v-img class="pa-6" src="@/assets/logobkpsdm.png" height="50" max-width="150" contain></v-img>
            <span>Copyright © 2020 - {{ new Date().getFullYear() }} BKPSDM Kota Bandung</span><br>
          </div>
          <div :class="{'d-flex flex-wrap-reverse align-center justify-center': $vuetify.breakpoint.smAndDown, 'd-flex align-center': $vuetify.breakpoint.mdAndUp}">
            <span :class="{'order-2': $vuetify.breakpoint.smAndDown, 'pa-7': $vuetify.breakpoint.mdAndUp}">Made with <v-icon color="red">mdi-heart</v-icon> in Bandung</span>
            <v-img :class="{'order-1': $vuetify.breakpoint.smAndDown, 'pa-7': $vuetify.breakpoint.mdAndUp}" src="@/assets/kota_bdg.png" height="50" max-width="100" contain></v-img>
          </div>
        </div>
        </v-img>
      </v-container>
      <v-footer color="primary" padless>
        <v-row
          justify="center"
          no-gutters
        >
          <v-col
            class="primary text-center white--text"
            cols="12"
          >
            <!-- {{ new Date().getFullYear() }} © <strong>BKPSDM Bandung </strong> -->
            <small>Copyright © 2020 - {{ new Date().getFullYear() }} BKPSDM Kota Bandung | {{MbVersion}}<!--.{{version_date}}--> . {{MbBuild}}</small>
          </v-col>
        </v-row>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import store from '@/store'

  export default {
    name:"Login",
    props: {
      source: String,
    },
    data () {
      return{
        title:process.env.VUE_APP_TITLE,
        username:'',
        password:'',
        year:2020,
        loading:false,
        simpanNIP:false,
        seePassword: false,
        errors: [],
        quotes:[
          {content: "“Datang bersama merupakan awal perjalanan, tetap bersama merupakan kemajuan. Bekerja sama dalam tim adalah kesuksesan.” ― Henry Ford", type:"quote"},
          {content: "“Struggle that you do today is the single way to build a better future.”", type:"quote"},
          {content: "“Orang hebat tidak dihasilkan dari kemudahan, kesenangan, dan kenyamanan. Mereka dibentuk melalui kesulitan, tantangan, dan air mata.” ― Dahlan Iskan", type:"quote"} ,
          {content: "“Develop a passion for learning, if you do you'll never cease to grow.”", type:"quote"},
          {content: "“Kepanikan adalah separuh penyakit. Ketenangan adalah separuh obat, kesabaran adalah permulaan kesembuhan.” ― Ibnu Sina", type:"quote"},
          {content: "“Find a group of people who challenge and inspire you, spend a lot of time with them, and it will change your life.” ― Amy Poehler", type:"quote"},
          {content: "“Keberhasilan bukanlah milik orang yang pintar. Keberhasilan adalah kepunyaan mereka yang senantiasa berusaha.” ― B.J. Habibie", type:"quote"},
          {content: "“Kadang kita terlalu sibuk memikirkan kesulitan-kesulitan sehingga kita tidak punya waktu untuk mensyukuri rahmat Tuhan.” ― Jenderal Sudirman", type:"quote"},
          {content: "“Kurang cerdas dapat diperbaiki dengan belajar. Kurang cakap dapat dihilangkan dengan pengalaman. Namun tidak jujur itu sulit diperbaiki.” ― Mohammad Hatta", type:"quote"},
          {content: "“Jangan takut jatuh, kerana yang tidak pernah memanjatlah yang tidak pernah jatuh. Yang takut gagal, kerana yang tidak pernah gagal hanyalah orang-orang yang tidak pernah melangkah.” ― Buya Hamka", type:"quote"},
          {content: '“Kecantikan yang abadi terletak pada keelokan adab dan ketinggian ilmu seseorang. Bukan terletak pada wajah dan pakaiannya.” ― Buya Hamka', type:'quote'},
          {content: '"Perjuanganku lebih mudah karena mengusir penjajah, perjuanganmu akan lebih sulit karena melawan bangsamu sendiri." - Soekarno', type:'quote'},
          {content: '“Jangan mudah tergelincir dalam saat-saat seperti ini, segala tipu muslihat dan provokasi-provokasi yang tampak atau tersembunyi dapat dilalui dengan selamat, kalau kita waspada dan bertindak sebagai patriot.” - Jenderal Sudirman', type:'quote'},
          {content: '“Cita-cita persatuan Indonesia itu bukan omong kosong, tetapi benar-benar didukung oleh kekuatan-kekuatan yang timbul pada akar sejarah bangsa kita sendiri.” - Mohammad Yamin', type:'quote'},
        ],
      }
    },
    computed:{
      tahun(){
        let years = ['-']
        let year = new Date().getFullYear()
        for (let i = 0; i < 5; i++){
          let temp = year - i
          if (temp >= 2021){
            years.push( temp)
          }
        }
        // var date = new Date()
        // var day = date.getDate()
        // if(day <= 10){
        //   years = [
        //     '2023',
        //     '2022',
        //     '2021',
        //   ]
        // }
        return years
      },
      MbVersion() {
         return 'Mangbagja v1.3'
      },
      MbBuild() {
         return 'Lrvl/240525' + ' . ' + '#643'
      },
      hasError(){
        return Boolean(this.err.message)
      },
      ...mapState({
        err : state => state.user.err
      })
    },
    watch:{
      username(newVal, oldVal){
        if (newVal !== oldVal){
          store.commit('user/CLEAR_ERROR')
        }
      },
      password(newVal, oldVal){
        if (newVal !== oldVal){
          store.commit('user/CLEAR_ERROR')
        }
      },
      hasError(val){
        if (val){
          this.loading=false
        }
      },
    },
    created(){
      // on create clear storage
      localStorage.clear()
      this.username = store.state.user.savedUsername
      // if username masih ada maka pastikan simpanNip di checklist
      this.simpanNIP = Boolean(store.state.user.savedUsername)
      // this.year = new Date().getFullYear()
      this.year = '-'
    },
    methods:{
      handleSubmit(e){
        this.errors = [];
        var error = false
        if(this.year == '-'){
          this.errors.push('Sorry, please choose the year first!');
          error = true
        }
        e.preventDefault()

        if (this.simpanNIP){
          store.commit('user/SAVE_USERNAME', this.username)
        } else {
          store.commit('user/SAVE_USERNAME', '')
        }
        if(!error){
          this.login({
            username:this.username,
            password:this.password,
            year: this.year
          })
          this.loading=true
        }

      },

      ...mapActions({
        login:"user/login"
      })
    }
  }
</script>

<style lang="css" scoped>
</style>
